import React, { useState } from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  List,
  ListItem,
  ListIcon,
  Heading,
  Spinner,
  border,
} from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";
import { createCheckoutSession } from "../stripe/createCheckoutSession";
import WidgetWrapper from "./Widgets/WidgetWrapper";
import { getUserBillingPortalLink } from "../calls";
import { useTranslation } from "react-i18next";

const PayPlanCard = ({ child, subscriptionTime, state, userProductID }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  function calculateDiscountPrice(currentPrice, discountPercentage) {
    // Ensure discountPercentage is in decimal form (e.g., 20% becomes 0.2)
    const discountFactor = 1 - discountPercentage / 100;

    // Calculate the original price
    const originalPrice = currentPrice / discountFactor;

    const discountPrice = originalPrice - currentPrice;

    return Math.floor(discountPrice);
  }

  const renderPriceDetails = () => {
    if (!child.prices || !child.prices[subscriptionTime]?.metadata.discount)
      return null;

    const priceConvert = child.prices[subscriptionTime].unit_amount / 100;
    const discountPercentage = child.prices[subscriptionTime].metadata.discount;

    const saveAmount = calculateDiscountPrice(priceConvert, discountPercentage);

    return (
      <Flex
        position={"absolute"}
        right={"10px"}
        gap={"10px"}
        top={"10px"}
        fontSize={"12px"}
      >
        <BoxDetails label={`${t("save")} ${saveAmount}kr`} />
        <BoxDetails label={`-${discountPercentage}%`} />
      </Flex>
    );
  };

  const BoxDetails = ({ label }) => (
    <Box
      position={"relative"}
      bg={"#5138EE"}
      color={"white"}
      fontWeight={"bold"}
      rounded={"lg"}
      paddingX={"5px"}
      paddingY={"5px"}
    >
      {label}
    </Box>
  );

  const renderPrice = () => {
    if (
      !subscriptionTime ||
      !child.prices ||
      !child.prices[subscriptionTime]?.unit_amount
    ) {
      return <Box my={3}></Box>;
    }
    let originalPrice =
      child.prices[subscriptionTime].unit_amount_decimal / 100;

    return (
      <Text my={3} fontSize={"32px"} fontWeight={"bold"}>
        {originalPrice.toString()}
        <span style={{ fontSize: "12px" }}>
          kr/
          {subscriptionTime === "month"
            ? t("month").toLocaleLowerCase()
            : t("year").toLocaleLowerCase()}
        </span>
      </Text>
    );
  };

  const renderFeatures = () => {
    return child.features.map((feature, index) => (
      <ListItem key={index} fontSize={"14px"}>
        <ListIcon color={"#5138EE"} as={CheckCircleIcon} />
        {feature.name}
      </ListItem>
    ));
  };

  const renderActionButton = () => {
    return (
      <Button
        mt={4}
        width={"100%"}
        p={4}
        bg={"#5138EE"}
        color={"white"}
        fontWeight={"bold"}
        onClick={() => handleActionClick()}
      >
        {renderActionButtonText()}
      </Button>
    );
  };

  const handleActionClick = async () => {
    if (state.stripeRole) {
      try {
        const { data } = await getUserBillingPortalLink(() =>
          state.user.getIdToken()
        );
        window.location.href = data.url;
      } catch (error) {
        console.log(error);
      }
    } else if (child.prices) {
      setLoading(child.metadata.firebaseRole);
      createCheckoutSession(state.user.uid, child.prices[subscriptionTime].id);
    } else {
      // Kontakta oss
    }
  };

  const renderActionButtonText = () => {
    if (!child.metadata.hideObject) {
      return loading ? (
        <Spinner></Spinner>
      ) : child.prices[subscriptionTime].id !== userProductID ? (
        state.stripeRole ? (
          t("changePlan")
        ) : (
          t("startPlan")
        )
      ) : (
        t("activePlan")
      );
    } else {
      return loading ? <Spinner></Spinner> : t("ContactUs");
    }
  };

  return (
    <Box flex={1} minW={"350px"} position={"relative"}>
      <WidgetWrapper
        child={
          <Flex flex={1} direction="column">
            {renderPriceDetails()}
            <Heading as="h3" mt={5} fontSize="lg" color={child.textColor}>
              {child.name}
            </Heading>
            <Text mt={2} fontSize="sm" color={child.textColor}>
              {child.description}
            </Text>
            <Flex
              flexDirection={"column"}
              role="list"
              fontSize="sm"
              spacing={6}
              height={"100%"}
            >
              {renderPrice()}
              <List mt={"auto"} flex={1} role="list" fontSize="sm" spacing={6}>
                {renderFeatures()}
              </List>
            </Flex>
            {renderActionButton()}
          </Flex>
        }
      />
    </Box>
  );
};

export default PayPlanCard;
