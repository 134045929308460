import { Grid, Flex, Spinner } from "@chakra-ui/react";

import { SettingsIcon, DeleteIcon } from "@chakra-ui/icons";
import Payplan from "./Payplan.jsx";
import Account from "./Account.jsx";
import { useLocation } from "react-router-dom";
import DeleteAccount from "./DeleteAccount.jsx";
import SystemBar from "../../../components/SystemBar.jsx";
import ChangePassword from "./ChangePassword.jsx";
import ChangeEmail from "./ChangeEmail.jsx";
import { useState, useEffect, useContext } from "react";
import { getUserSubscription } from "../../../calls.js";

import { UserContext } from "../../../context/index.js";
import { getStripeProducts } from "../../../calls";
import { useTranslation } from "react-i18next";

const Profile = () => {
  const { t } = useTranslation();

  const navbar = [
    {
      text: t("general"),
      redirect: "",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <SettingsIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <SettingsIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
    ,
    /*{
      text: "Plan",
      redirect: "betalning",
      extraCSSButton: { fontSize: "14px" },
      icon: {
        active: <SettingsIcon color={"black"} w={"20px"} h={"20px"} />,
        inActive: <SettingsIcon color={"white"} w={"20px"} h={"20px"} />,
      },
      notForOrgMembers: true,
    },*/
    {
      text: t("removeAccount"),
      redirect: "radera",
      extraCSSContainer: { marginLeft: "auto" },
      extraCSSButton: {
        bg: "#ee3838",
        fontSize: "12px",
        marginLeft: "auto",
      },
      extraCSSText: { color: "white" },
      icon: {
        active: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
        inActive: <DeleteIcon color={"white"} w={"20px"} h={"20px"} />,
      },
    },
  ];

  const [state] = useContext(UserContext);
  const location = useLocation();
  const [userPlan, setUserPlan] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (userPlan) {
      setLoading(false);
      return;
    }

    if (!state.stripeRole) {
      setLoading(false);
      return;
    }

    const fetchData = async () => {
      try {
        const data = await getUserSubscription(
          () => state.user.getIdToken(),
          state.sub
        );
        setUserPlan({ ...data.data.subscriptionPlan });
      } catch (error) {}
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [products, setProducts] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await getStripeProducts(() => state.user.getIdToken());
        setProducts(data.products);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <>
      {!loading ? (
        <Grid color={"white"} gap={"10px"} padding={"10px 0px"}>
          <Flex gap={"10px"} h={"fit-content"} fontSize={"14px"}>
            <SystemBar state={state} navbar={navbar} pathname={`/konto`} />
          </Flex>
          <Grid height={"100%"} gap={5}>
            {location.pathname === "/konto/updatera-losenord" && (
              <ChangePassword />
            )}
            {location.pathname === "/konto/updatera-email" && <ChangeEmail />}
            {location.pathname === "/konto" && <Account userPlan={userPlan} />}
            {/*(!state.organization ||
              state.organization.owner === state.user.uid) &&
              location.pathname === "/konto/betalning" && (
                <Payplan userProductID={userPlan?.id} products={products} />
              )*/}
            {location.pathname === "/konto/radera" && <DeleteAccount />}
          </Grid>
        </Grid>
      ) : (
        <Flex
          height={"100%"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Spinner size={"xl"} color={"#062C8E"}></Spinner>
        </Flex>
      )}
    </>
  );
};

export default Profile;
