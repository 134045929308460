import {
  Text,
  Flex,
  Grid,
  Button,
  Switch,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";

import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { UserContext } from "../../../context/index.js";
import { useContext } from "react";
import { isPasswordAndEmail } from "../../../functions/isGoogleUser.js";

import { useNavigate } from "react-router-dom";

import { timeToDate } from "../../../functions/timeToDate.js";
import { getUserBillingPortalLink } from "../../../calls.js";
import { useTranslation } from "react-i18next";

import { subscribeToEmail } from "../../../calls.js";

const Account = ({ userPlan }) => {
  const { t } = useTranslation();

  const [state, dispatch] = useContext(UserContext);
  const navigate = useNavigate();

  return (
    <Flex gap={4} flexDirection={["column", "column", "row"]}>
      <Grid gap={4} w={["100%", "100%", "50%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} gap={5} w={"100%"}>
              <Flex flexDirection={"column"} gap={1}>
                <Flex
                  fontWeight={"medium"}
                  color={"#5138EE"}
                  justifyContent={"right"}
                  flexDirection={"column"}
                  position={"relative"}
                >
                  <InputComponent
                    readOnly={true}
                    label={t("email")}
                    value={state.user}
                    keyValue={"email"}
                    type={"text"}
                  />
                  <Flex
                    fontSize={"14px"}
                    position={"absolute"}
                    textAlign={"right"}
                    bottom={-6}
                    right={0}
                    gap={2}
                  >
                    {isPasswordAndEmail(state.user) && (
                      <>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-email")}
                        >
                          {t("changeEmail")}{" "}
                        </Text>
                        <Text>/</Text>
                        <Text
                          className="textHover"
                          onClick={() => navigate("updatera-losenord")}
                        >
                          {t("changePassword")}{" "}
                        </Text>
                      </>
                    )}
                  </Flex>
                </Flex>
              </Flex>

              <InputComponent
                readOnly={true}
                label={t("memberFrom")}
                value={timeToDate(state.user.metadata.createdAt)}
                type={"text"}
              />
              <InputComponent
                readOnly={true}
                label={"User ID"}
                value={state.user.uid}
                type={"text"}
              />
              <InputComponent
                readOnly={true}
                label={t("accountPlan")}
                value={state.stripeRole ? state.stripeRole : t("freeAccount")}
                type={"text"}
              />
              <FormControl display="flex" alignItems="center">
                <FormLabel htmlFor="email-alerts" mb="0">
                  {t("emailSub")}
                </FormLabel>
                <Switch
                  value={state.emailSubscription}
                  id="email-alerts"
                  isChecked={state.emailSubscription}
                  onChange={async (e) =>
                    await subscribeToEmail(
                      () => state.user.getIdToken(),
                      !state.emailSubscription
                    ).then((e) => {
                      if (e.status) {
                        dispatch({
                          ...state,
                          emailSubscription: !state.emailSubscription,
                        });
                      }
                    })
                  }
                />
              </FormControl>
            </Flex>
          }
        />
      </Grid>
      {/*<Grid w={["100%", "100%", "50%"]} marginBottom={"auto"}>
        <WidgetWrapper
          child={
            <Flex flexDirection={"column"} gap={5} w={"100%"}>
              {
                <InputComponent
                  readOnly={true}
                  label={t("payPlan")}
                  value={state.stripeRole ? state.stripeRole : "Gratis"}
                  type={"text"}
                />
              }
              <InputComponent
                readOnly={true}
                label={t("payPeriod")}
                value={
                  userPlan.interval
                    ? userPlan.interval === "month"
                      ? t("month")
                      : t("year")
                    : ""
                }
                type={"text"}
              />
              <Flex marginLeft={"auto"}>
                <Button
                  mt={4}
                  width={"100%"}
                  p={4}
                  bg={"#5138EE"}
                  color={"white"}
                  fontWeight={"bold"}
                  onClick={async () => {
                    try {
                      const { data } = await getUserBillingPortalLink(() =>
                        state.user.getIdToken()
                      );
                      window.open(data.url, "_blank");
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  {t("handlePlan")}
                </Button>
              </Flex>
            </Flex>
          }
        ></WidgetWrapper>
      </Grid>*/}
    </Flex>
  );
};
export default Account;
